import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';


import { Dropzone } from "dropzone";
import { useEffect, useRef } from 'react';
import axios from 'axios';



const ImageCreate = () => {
      const { folderId } = useParams();
      const dropzoneRef = useRef(null);

      const [loading, setLoading] = useState(false);

      useEffect(() => {
            if (dropzoneRef.current) {
                  const dropzone = new Dropzone(dropzoneRef.current, { url: `http://127.0.0.1:8000/api/dashboard/images/products/import?folder=${folderId}` });
            }
      }, []);


      return (
            <>
                  <Row>
                        <Col>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>Add Image</Card.Title>
                                          <Link to="/dashboard/images" className='btn btn-secondary'>Back</Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <div ref={dropzoneRef} className="dropzone"></div>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      )
}

export default ImageCreate;