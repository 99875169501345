import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const CatalogueEdit = () => {
      const { catalogueId } = useParams();

      const [catalogue, setCatalogue] = useState([]);
      const [title, setTitle] = useState('');
      const [image, setImage] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            ).then((response) => {
                  if (response.data.result === 'success') {
                        setCatalogue(response.data.catalogue);
                        setTitle(response.data.catalogue.title);
                        setStatus(response.data.catalogue.status);
                        setImage(response.data.catalogue.image);
                  }
            });
      }, []);

      const handleImage = (file) => {
            setImage(file);
      };

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title ? title : catalogue.title,
                  status: status ? status : catalogue.status,
                  image: image
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              setLoading(false);
                        }
                        if (response.data.result === 'error') {
                              setLoading(false);
                              if (response.data.message) {
                                    toast.error(response.data.message);
                              }
                              if (response.data.message['status']) {
                                    toast.error(
                                          response.data.message['status']
                                    );
                              }
                              if (response.data.message['image']) {
                                    toast.error(response.data.message['image']);
                              }
                              if (response.data.errorMessage) {
                                    toast.error(response.data.errorMessage);
                              }
                        }
                  });
      };

      return (
            <>
                  <div className="">
                        <div className="row">
                              <div className="col-md-12">
                                    <div className="card">
                                          <div className="card-header">
                                                <span className="card-title">
                                                      Create catalogue
                                                </span>
                                                <Link
                                                      to="/dashboard/catalogues"
                                                      className="btn btn-secondary"
                                                >
                                                      Back
                                                </Link>
                                          </div>
                                          <div className="card-body">
                                                <form
                                                      onSubmit={
                                                            handleFormSubmit
                                                      }
                                                >
                                                      <div className="row g-3">
                                                            <div className="col-lg-6">
                                                                  <label>
                                                                        Title
                                                                  </label>
                                                                  <input
                                                                        className="form-control"
                                                                        defaultValue={
                                                                              title
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setTitle(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                  <label>
                                                                        Status
                                                                  </label>
                                                                  <select
                                                                        value={
                                                                              status
                                                                        }
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              setStatus(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        className="form-select"
                                                                  >
                                                                        <option>
                                                                              Select
                                                                              One
                                                                        </option>
                                                                        <option value="0">
                                                                              Active
                                                                        </option>
                                                                        <option value="1">
                                                                              Disable
                                                                        </option>
                                                                  </select>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                  <label>
                                                                        PDF File
                                                                  </label>
                                                                  <input
                                                                        accept="image/*"
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              handleImage(
                                                                                    event
                                                                                          .target
                                                                                          .files[0]
                                                                              )
                                                                        }
                                                                        className="form-control"
                                                                        type="file"
                                                                  />
                                                                  {catalogue.file && (
                                                                        <a
                                                                              download
                                                                              className="btn btn-warning mt-3"
                                                                              href={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${image}`}
                                                                        >
                                                                              {' '}
                                                                              Download{' '}
                                                                        </a>
                                                                  )}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                  <label>
                                                                        Featured
                                                                        Image
                                                                  </label>
                                                                  <input
                                                                        accept="image/*"
                                                                        onChange={(
                                                                              event
                                                                        ) =>
                                                                              handleImage(
                                                                                    event
                                                                                          .target
                                                                                          .files[0]
                                                                              )
                                                                        }
                                                                        className="form-control"
                                                                        type="file"
                                                                  />
                                                                  {image && (
                                                                        <img
                                                                              className="mt-3"
                                                                              style={{
                                                                                    height: '100px',
                                                                                    width: '200px'
                                                                              }}
                                                                              src={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${image}`}
                                                                        />
                                                                  )}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                  <button
                                                                        type="submit"
                                                                        className="btn btn-success btn-sm"
                                                                  >
                                                                        Submit
                                                                        {loading && (
                                                                              <Spinner
                                                                                    animation="border"
                                                                                    role="status"
                                                                              >
                                                                                    <span className="visually-hidden">
                                                                                          Loading...
                                                                                    </span>
                                                                              </Spinner>
                                                                        )}
                                                                  </button>
                                                            </div>
                                                      </div>
                                                </form>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default CatalogueEdit;
