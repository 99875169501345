import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FolderForm from '../../components/form/folders/FolderForm';

import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const FolderCreate = () => {
      const [validated, setValidated] = useState(false);
      const [title, setTitle] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const handleFormSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                  event.stopPropagation();
            }

            setValidated(true);

            if (validated) {
                  const data = {
                        title: title,
                        status: status
                  };
                  axios.post(
                        'http://127.0.0.1:8000/api/dashboard/folders',
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    toast.success(
                                          'Information has been added.'
                                    );
                                    $('form').trigger('reset');
                                    setLoading(false);
                              }
                              if (response.data.result === 'error') {
                                    toast.error(response.data.message);
                                    setLoading(false);
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            }
      };

      return (
            <>
                  <Row>
                        <Col>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>Create Folder</Card.Title>
                                          <Link
                                                to="/dashboard/folders"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <FolderForm
                                                validated={validated}
                                                handleFormSubmit={
                                                      handleFormSubmit
                                                }
                                                setTitle={setTitle}
                                                setStatus={setStatus}
                                          />
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FolderCreate;
