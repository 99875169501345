import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

const FolderForm = ({
      validated,
      handleFormSubmit,
      setTitle,
      setStatus,
      title,
      status
}) => {
      return (
            <>
                  <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                        <Row className='g-3'>
                              <Col>
                                    <Form.Group>
                                          <Form.Label>Folder Name</Form.Label>
                                          <Form.Control
                                                type="text"
                                                placeholder="Folder Name"
                                                onChange={(event) => setTitle(event.target.value)}
                                                defaultValue={title}
                                                required
                                          />
                                    </Form.Group>
                              </Col>
                              <Col>
                                    <Form.Group>
                                          <Form.Label>Folder Status</Form.Label>
                                          <Form.Select onChange={(event) => setStatus(event.target.value)} value={status} required>
                                                <option>Choose Type</option>
                                                <option value="1">Active</option>
                                                <option value="0">Disable</option>
                                          </Form.Select>
                                    </Form.Group>
                              </Col>
                              <Col xs={12}>
                                    <Button type='submit'>
                                          {title ? 'Update' : 'Create'} Folder
                                    </Button>
                              </Col>
                        </Row>
                  </Form>
            </>
      )
}

export default FolderForm;