import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import $ from 'jquery';
import { Card, Col, Row, Form } from 'react-bootstrap';
import CardHeader from '../../../components/common/CardHeader';
import LinkButton from '../../../components/common/LinkButton';
import {
      FormButton,
      FormInput,
      FormStatusSelect,
      FormTextarea
} from '../../../components/form';

const DealerContactCreate = () => {
      const [title, setTitle] = useState('');
      const [address, setAddress] = useState('');
      const [phone, setPhone] = useState('');
      const [email, setEmail] = useState('');
      const [fullName, setFullName] = useState('');
      const [zone, setZone] = useState('');
      const [district, setDistrict] = useState('');
      const [province, setProvince] = useState('');
      const [status, setStatus] = useState('');
      const [location, setLocation] = useState('');
      const [loading, setLoading] = useState('');

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  address: address,
                  email: email,
                  phone: phone,
                  full_name: fullName,
                  zone: zone,
                  district: district,
                  province: province,
                  status: status,
                  location: location
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_contacts`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }

                        if (response.data.result === 'error') {
                              if (response.data.message.full_name) {
                                    toast.error(
                                          response.data.message.full_name
                                    );
                              }
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.phone) {
                                    toast.error(response.data.message.phone);
                              }
                              if (response.data.message.zone) {
                                    toast.error(response.data.message.zone);
                              }
                              if (response.data.message.district) {
                                    toast.error(response.data.message.district);
                              }
                              if (response.data.message.province) {
                                    toast.error(response.data.message.province);
                              }
                              if (response.data.message.location) {
                                    toast.error(response.data.message.location);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create Dealer Contact">
                                                <LinkButton
                                                      link="/dashboard/dealer/contacts"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Full Name"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setFullName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Store Title"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Address"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setAddress
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Province"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setProvince
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Zone"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setZone
                                                            }
                                                      />
                                                      <FormInput
                                                            title="District"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setDistrict
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Phone No."
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setPhone
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Email Address"
                                                            classes={4}
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Google Location"
                                                            classes={12}
                                                            handleChange={
                                                                  setLocation
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default DealerContactCreate;
