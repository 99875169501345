import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import toast from 'react-hot-toast';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const TestimonialShow = () => {
      const { testimonialId } = useParams();
      const [testimonial, setTestimonial] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonialId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonial(response.data.testimonial);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Testimonial Details">
                                          <LinkButton
                                                link="/dashboard/testimonials"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg="4">
                                                      <div className="card">
                                                            <div className="card-body text-center">
                                                                  <img
                                                                        src={`${testimonial.image}`}
                                                                        className="w-100"
                                                                  />
                                                            </div>
                                                      </div>
                                                </Col>
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        testimonial.full_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Company Name"
                                                                  value={
                                                                        testimonial.company_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Position"
                                                                  value={
                                                                        testimonial.position
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        testimonial.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By: "
                                                                  value={
                                                                        testimonial.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        testimonial.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Message"
                                                                  value={
                                                                        testimonial.message
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default TestimonialShow;
