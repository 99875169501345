import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CardHeader from '../../components/common/CardHeader';
import { Card, Col, Row } from 'react-bootstrap';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';
import ZoneListBox from '../../components/backend/ZoneListBox';
import DistrictListBox from '../../components/backend/DistrictListBox';

const ProvinceShow = () => {
      const { provinceId } = useParams();
      const [province, setProvince] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/provinces/${provinceId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProvince(response.data.province);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Province Detail">
                                          <LinkButton
                                                link="/dashboard/provinces"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        province?.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Zone "
                                                                  value={
                                                                        province?.zones
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="District"
                                                                  value={
                                                                        province?.districts
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By "
                                                                  value={
                                                                        province?.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date "
                                                                  value={
                                                                        province?.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        province?.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>

                              <Card className="mt-4">
                                    <CardHeader title="Zone List" />
                                    <ZoneListBox provinceId={provinceId} />
                              </Card>

                              <Card className="mt-4">
                                    <CardHeader title="District List" />
                                    <DistrictListBox provinceId={provinceId} />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProvinceShow;
