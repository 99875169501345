import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from '../backend/dashboard';

// Category
import {
      CategoryList,
      CategoryCreate,
      CategoryEdit,
      CategoryShow
} from '../backend/category';
import {
      ProductCreate,
      ProductEdit,
      ProductList,
      ProductOrder,
      ProductShow
} from '../backend/products';
import { AreaList, AreaCreate, AreaEdit, AreaShow } from '../backend/area';
import {
      DesignList,
      DesignCreate,
      DesignEdit,
      DesignShow
} from '../backend/design';
import { ColorList, ColorCreate, ColorEdit, ColorShow } from '../backend/color';
import {
      FinishList,
      FinishCreate,
      FinishEdit,
      FinishShow
} from '../backend/finish';
import { SizeList, SizeCreate, SizeEdit, SizeShow } from '../backend/size';
import {
      RegionList,
      RegionCreate,
      RegionEdit,
      RegionShow
} from '../backend/region';
import {
      CollectionList,
      CollectionCreate,
      CollectionEdit,
      CollectionShow
} from '../backend/collection';
import { UnitList, UnitCreate, UnitEdit, UnitShow } from '../backend/unit';
import { TypeList, TypeCreate, TypeEdit, TypeShow } from '../backend/type';
import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow
} from '../backend/banner';
import {
      TestimonialList,
      TestimonialCreate,
      TestimonialShow,
      TestimonialEdit
} from '../backend/testimonials';
import {
      ClientList,
      ClientCreate,
      ClientEdit,
      ClientShow
} from '../backend/client';
import CatalogueList from '../backend/catalogue/CatalogueList';
import CatalogueCreate from '../backend/catalogue/CatalogueCreate';
import PageNotFound from '../PageNotFound';
import CatalogueShow from '../backend/catalogue/CatalogueShow';
import CatalogueEdit from '../backend/catalogue/CatalogueEdit';
import CatalogueRequestList from '../backend/catalogoueRequest/CatalogueRequestList';
import { PageList, PageCreate, PageEdit, PageShow } from '../backend/pages';
import AboutList from '../backend/about/AboutList';
import AboutCreate from '../backend/about/AboutCreate';
import AboutEdit from '../backend/about/AboutEdit';
import AboutShow from '../backend/about/AboutShow';
import {
      DealerContactList,
      DealerContactCreate,
      DealerContactEdit,
      DealerContactShow
} from '../backend/dealer/contact';
import ContactList from '../backend/contact/ContactList';
import ContactCreate from '../backend/contact/ContactCreate';
import ContactEdit from '../backend/contact/ContactEdit';
import ContactShow from '../backend/contact/ContactShow';
import FolderList from '../backend/folders/FolderList';
import ImageList from '../backend/Image/ImageList';
import FolderCreate from '../backend/folders/FolderCreate';
import FolderEdit from '../backend/folders/FolderEdit';
import FolderShow from '../backend/folders/FolderShow';
import ImageCreate from '../backend/Image/ImageCreate';
import { RoleCreate, RoleEdit, RoleList } from '../backend/roles';
import {
      BlogCategoryList,
      BlogCategoryCreate,
      BlogCategoryEdit,
      BlogCategoryShow
} from '../backend/blogCategory';
import { BlogCreate, BlogEdit, BlogShow, BlogTable } from '../backend/blog';
import {
      FaqTypeCreate,
      FaqTypeEdit,
      FaqTypeList,
      FaqTypeShow
} from '../backend/faqType';
import { FaqCreate, FaqEdit, FaqList, FaqShow } from '../backend/faqs';
import {
      FaqRequestEdit,
      FaqRequestList,
      FaqRequestShow
} from '../backend/faqRequest';
import {
      PluginCreate,
      PluginEdit,
      PluginList,
      PluginShow
} from '../backend/plugins';
import { JobCreate, JobEdit, JobList, JobShow } from '../backend/jobs';
import { CandidateList, CandidateShow } from '../backend/candidates';
import {
      ComplainTypeCreate,
      ComplainTypeEdit,
      ComplainTypeList,
      ComplainTypeShow
} from '../backend/complainTypes';
import { ComplainList, ComplainShow } from '../backend/complains';
import {
      SubscriberCreate,
      SubscriberEdit,
      SubscriberList
} from '../backend/subscribers';
import {
      ContactRequestList,
      ContactRequestShow
} from '../backend/contactRequest';
import { UserCreate, UserEdit, UserList, UserShow } from '../backend/users';
import {
      ProvinceCreate,
      ProvinceEdit,
      ProvinceList,
      ProvinceShow
} from '../backend/province';
import { ZoneCreate, ZoneList, ZoneEdit, ZoneShow } from '../backend/zones';
import {
      DistrictCreate,
      DistrictEdit,
      DistrictList,
      DistrictShow
} from '../backend/districts';
import {
      JobDepartmentCreate,
      JobDepartmentEdit,
      JobDepartmentList,
      JobDepartmentShow
} from '../backend/job_departments';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Category */}
                        <Route
                              path="/dashboard/categories"
                              element={<CategoryList />}
                        />
                        <Route
                              path="/dashboard/categories/create"
                              element={<CategoryCreate />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/edit"
                              element={<CategoryEdit />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/show"
                              element={<CategoryShow />}
                        />

                        {/* Product */}
                        <Route
                              path="/dashboard/products"
                              element={<ProductList />}
                        />
                        <Route
                              path="/dashboard/products/create"
                              element={<ProductCreate />}
                        />
                        <Route
                              path="/dashboard/products/:productId/edit"
                              element={<ProductEdit />}
                        />
                        <Route
                              path="/dashboard/products/:productId/show"
                              element={<ProductShow />}
                        />
                        <Route
                              path="/dashboard/products/:areaId/order"
                              element={<ProductOrder />}
                        />

                        {/* Product Area */}
                        <Route path="/dashboard/areas" element={<AreaList />} />
                        <Route
                              path="/dashboard/areas/create"
                              element={<AreaCreate />}
                        />
                        <Route
                              path="/dashboard/areas/:areaId/edit"
                              element={<AreaEdit />}
                        />
                        <Route
                              path="/dashboard/areas/:areaId/show"
                              element={<AreaShow />}
                        />

                        {/* Product Design */}
                        <Route
                              path="/dashboard/designs"
                              element={<DesignList />}
                        />
                        <Route
                              path="/dashboard/designs/create"
                              element={<DesignCreate />}
                        />
                        <Route
                              path="/dashboard/designs/:designId/edit"
                              element={<DesignEdit />}
                        />
                        <Route
                              path="/dashboard/designs/:designId/show"
                              element={<DesignShow />}
                        />

                        {/* Product Color */}
                        <Route
                              path="/dashboard/colors"
                              element={<ColorList />}
                        />
                        <Route
                              path="/dashboard/colors/create"
                              element={<ColorCreate />}
                        />
                        <Route
                              path="/dashboard/colors/:colorId/edit"
                              element={<ColorEdit />}
                        />
                        <Route
                              path="/dashboard/colors/:colorId/show"
                              element={<ColorShow />}
                        />

                        {/* Product Finish */}
                        <Route
                              path="/dashboard/finishes"
                              element={<FinishList />}
                        />
                        <Route
                              path="/dashboard/finishes/create"
                              element={<FinishCreate />}
                        />
                        <Route
                              path="/dashboard/finishes/:finishId/edit"
                              element={<FinishEdit />}
                        />
                        <Route
                              path="/dashboard/finishes/:finishId/show"
                              element={<FinishShow />}
                        />

                        {/* Product Size */}
                        <Route path="/dashboard/sizes" element={<SizeList />} />
                        <Route
                              path="/dashboard/sizes/create"
                              element={<SizeCreate />}
                        />
                        <Route
                              path="/dashboard/sizes/:sizeId/edit"
                              element={<SizeEdit />}
                        />
                        <Route
                              path="/dashboard/sizes/:sizeId/show"
                              element={<SizeShow />}
                        />

                        {/* Product Region */}
                        <Route
                              path="/dashboard/regions"
                              element={<RegionList />}
                        />
                        <Route
                              path="/dashboard/regions/create"
                              element={<RegionCreate />}
                        />
                        <Route
                              path="/dashboard/regions/:regionId/edit"
                              element={<RegionEdit />}
                        />
                        <Route
                              path="/dashboard/regions/:regionId/show"
                              element={<RegionShow />}
                        />

                        {/* Product Collection */}
                        <Route
                              path="/dashboard/collections"
                              element={<CollectionList />}
                        />
                        <Route
                              path="/dashboard/collections/create"
                              element={<CollectionCreate />}
                        />
                        <Route
                              path="/dashboard/collections/:collectionId/edit"
                              element={<CollectionEdit />}
                        />
                        <Route
                              path="/dashboard/collections/:collectionId/show"
                              element={<CollectionShow />}
                        />

                        {/* Product Unit */}
                        <Route path="/dashboard/units" element={<UnitList />} />
                        <Route
                              path="/dashboard/units/create"
                              element={<UnitCreate />}
                        />
                        <Route
                              path="/dashboard/units/:unitId/edit"
                              element={<UnitEdit />}
                        />
                        <Route
                              path="/dashboard/units/:unitId/show"
                              element={<UnitShow />}
                        />

                        {/* Product Type */}
                        <Route path="/dashboard/types" element={<TypeList />} />
                        <Route
                              path="/dashboard/types/create"
                              element={<TypeCreate />}
                        />
                        <Route
                              path="/dashboard/types/:typeId/edit"
                              element={<TypeEdit />}
                        />
                        <Route
                              path="/dashboard/types/:typeId/show"
                              element={<TypeShow />}
                        />

                        {/* Faq */}
                        <Route path="/dashboard/faqs" element={<FaqList />} />
                        <Route
                              path="/dashboard/faqs/create"
                              element={<FaqCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/edit"
                              element={<FaqEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/show"
                              element={<FaqShow />}
                        />

                        {/* Faq Type */}
                        <Route
                              path="/dashboard/faqs/types"
                              element={<FaqTypeList />}
                        />
                        <Route
                              path="/dashboard/faqs/types/create"
                              element={<FaqTypeCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/edit"
                              element={<FaqTypeEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/show"
                              element={<FaqTypeShow />}
                        />

                        {/* Faq Request */}
                        <Route
                              path="/dashboard/faqs/requests"
                              element={<FaqRequestList />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/show"
                              element={<FaqRequestShow />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/edit"
                              element={<FaqRequestEdit />}
                        />

                        {/* Testimonial */}
                        <Route
                              path="/dashboard/testimonials"
                              element={<TestimonialList />}
                        />
                        <Route
                              path="/dashboard/testimonials/create"
                              element={<TestimonialCreate />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/edit"
                              element={<TestimonialEdit />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/show"
                              element={<TestimonialShow />}
                        />

                        {/* Client */}
                        <Route
                              path="/dashboard/clients"
                              element={<ClientList />}
                        />
                        <Route
                              path="/dashboard/clients/create"
                              element={<ClientCreate />}
                        />
                        <Route
                              path="/dashboard/clients/:clientId/edit"
                              element={<ClientEdit />}
                        />
                        <Route
                              path="/dashboard/clients/:clientId/show"
                              element={<ClientShow />}
                        />

                        {/* Plugin */}
                        <Route
                              path="/dashboard/plugins"
                              element={<PluginList />}
                        />
                        <Route
                              path="/dashboard/plugins/create"
                              element={<PluginCreate />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/edit"
                              element={<PluginEdit />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/show"
                              element={<PluginShow />}
                        />

                        {/* Job */}
                        <Route path="/dashboard/jobs" element={<JobList />} />
                        <Route
                              path="/dashboard/jobs/create"
                              element={<JobCreate />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/edit"
                              element={<JobEdit />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/show"
                              element={<JobShow />}
                        />

                        {/* Job Department */}
                        <Route
                              path="/dashboard/job_departments"
                              element={<JobDepartmentList />}
                        />
                        <Route
                              path="/dashboard/job_departments/create"
                              element={<JobDepartmentCreate />}
                        />
                        <Route
                              path="/dashboard/job_departments/:departmentId/edit"
                              element={<JobDepartmentEdit />}
                        />
                        <Route
                              path="/dashboard/job_departments/:departmentId/show"
                              element={<JobDepartmentShow />}
                        />

                        {/* Candidate */}
                        <Route
                              path="/dashboard/candidates"
                              element={<CandidateList />}
                        />
                        <Route
                              path="/dashboard/candidates/:candidateId/show"
                              element={<CandidateShow />}
                        />

                        {/* Complain */}
                        <Route
                              path="/dashboard/complains"
                              element={<ComplainList />}
                        />
                        <Route
                              path="/dashboard/complains/:complainId/show"
                              element={<ComplainShow />}
                        />

                        {/* Complain Type */}
                        <Route
                              path="/dashboard/complains/types"
                              element={<ComplainTypeList />}
                        />
                        <Route
                              path="/dashboard/complains/types/create"
                              element={<ComplainTypeCreate />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/edit"
                              element={<ComplainTypeEdit />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/show"
                              element={<ComplainTypeShow />}
                        />

                        {/* Subscriber */}
                        <Route
                              path="/dashboard/subscribers"
                              element={<SubscriberList />}
                        />
                        <Route
                              path="/dashboard/subscribers/create"
                              element={<SubscriberCreate />}
                        />
                        <Route
                              path="/dashboard/subscribers/:subscriberId/edit"
                              element={<SubscriberEdit />}
                        />

                        {/* Contact Request */}
                        <Route
                              path="/dashboard/contact/requests"
                              element={<ContactRequestList />}
                        />
                        <Route
                              path="/dashboard/contact/requests/:contactId/show"
                              element={<ContactRequestShow />}
                        />

                        {/* Catalogue */}
                        <Route
                              path="/dashboard/catalogues"
                              element={<CatalogueList />}
                        />
                        <Route
                              path="/dashboard/catalogues/create"
                              element={<CatalogueCreate />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/edit"
                              element={<CatalogueEdit />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/show"
                              element={<CatalogueShow />}
                        />

                        {/* Catalogue Request */}
                        <Route
                              path="/dashboard/catalogues/downloaded/lists"
                              element={<CatalogueRequestList />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Blog */}
                        <Route
                              path="/dashboard/blogs"
                              element={<BlogTable />}
                        />
                        <Route
                              path="/dashboard/blogs/create"
                              element={<BlogCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/edit"
                              element={<BlogEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/show"
                              element={<BlogShow />}
                        />

                        {/* Blog Category */}
                        <Route
                              path="/dashboard/blogs/categories/list"
                              element={<BlogCategoryList />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/create"
                              element={<BlogCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/edit"
                              element={<BlogCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/show"
                              element={<BlogCategoryShow />}
                        />

                        {/* About */}
                        <Route
                              path="/dashboard/abouts"
                              element={<AboutList />}
                        />
                        <Route
                              path="/dashboard/abouts/create"
                              element={<AboutCreate />}
                        />
                        <Route
                              path="/dashboard/abouts/:aboutId/edit"
                              element={<AboutEdit />}
                        />
                        <Route
                              path="/dashboard/abouts/:aboutId/show"
                              element={<AboutShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Dealer Contact */}
                        <Route
                              path="/dashboard/dealer/contacts"
                              element={<DealerContactList />}
                        />
                        <Route
                              path="/dashboard/dealer/contacts/create"
                              element={<DealerContactCreate />}
                        />
                        <Route
                              path="/dashboard/dealer/contacts/:contactId/edit"
                              element={<DealerContactEdit />}
                        />
                        <Route
                              path="/dashboard/dealer/contacts/:contactId/show"
                              element={<DealerContactShow />}
                        />

                        {/* Contact Book */}
                        <Route
                              path="/dashboard/contacts"
                              element={<ContactList />}
                        />
                        <Route
                              path="/dashboard/contacts/create"
                              element={<ContactCreate />}
                        />
                        <Route
                              path="/dashboard/contacts/:contactId/edit"
                              element={<ContactEdit />}
                        />
                        <Route
                              path="/dashboard/contacts/:contactId/show"
                              element={<ContactShow />}
                        />

                        <Route
                              path="/dashboard/folders"
                              element={<FolderList />}
                        />
                        <Route
                              path="/dashboard/folders/create"
                              element={<FolderCreate />}
                        />
                        <Route
                              path="/dashboard/folders/:folderId/edit"
                              element={<FolderEdit />}
                        />
                        <Route
                              path="/dashboard/folders/:folderId/show"
                              element={<FolderShow />}
                        />

                        {/* Image */}
                        <Route
                              path="/dashboard/images"
                              element={<ImageList />}
                        />
                        <Route
                              path="/dashboard/images/:folderId/folder"
                              element={<ImageCreate />}
                        />

                        {/* Province */}
                        <Route
                              path="/dashboard/provinces"
                              element={<ProvinceList />}
                        />
                        <Route
                              path="/dashboard/provinces/create"
                              element={<ProvinceCreate />}
                        />
                        <Route
                              path="/dashboard/provinces/:provinceId/edit"
                              element={<ProvinceEdit />}
                        />
                        <Route
                              path="/dashboard/provinces/:provinceId/show"
                              element={<ProvinceShow />}
                        />

                        {/* Zone */}
                        <Route path="/dashboard/zones" element={<ZoneList />} />
                        <Route
                              path="/dashboard/zones/create"
                              element={<ZoneCreate />}
                        />
                        <Route
                              path="/dashboard/zones/:zoneId/edit"
                              element={<ZoneEdit />}
                        />
                        <Route
                              path="/dashboard/zones/:zoneId/show"
                              element={<ZoneShow />}
                        />

                        {/* District */}
                        <Route
                              path="/dashboard/districts"
                              element={<DistrictList />}
                        />
                        <Route
                              path="/dashboard/districts/create"
                              element={<DistrictCreate />}
                        />
                        <Route
                              path="/dashboard/districts/:districtId/edit"
                              element={<DistrictEdit />}
                        />
                        <Route
                              path="/dashboard/districts/:districtId/show"
                              element={<DistrictShow />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />
                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
