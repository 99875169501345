import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';

const FolderShow = () => {
      const { folderId } = useParams();
      const [loading, setLoading] = useState(false);
      const [folder, setFolder] = useState([]);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  await axios.get(`http://127.0.0.1:8000/api/dashboard/folders/${folderId}`, {
                        headers: {
                              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }).then((response) => {
                        if (response.data.result === 'success') {
                              setFolder(response.data.folder);
                        }
                        setLoading(false);
                  });
            }

            fetchData();
      }, []);

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>Folder Details</Card.Title>
                                          <Link to="/dashboard/folders" className='btn btn-secondary'>Back</Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <Row>
                                                <Col xs={4}>
                                                      <ListGroup>
                                                            <ListGroup.Item> <strong>Folder Name : </strong> {folder.title || <Skeleton />} </ListGroup.Item>
                                                            <ListGroup.Item> <strong>Folder Status : </strong>
                                                                  {!loading ? (
                                                                        folder.status ? (
                                                                              <>
                                                                                    <span className='text-success'>Active</span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className='text-warning'>Disable</span>
                                                                              </>
                                                                        )

                                                                  ) : (
                                                                        <>
                                                                              <Skeleton />
                                                                        </>
                                                                  )}
                                                            </ListGroup.Item>
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      )
}

export default FolderShow;