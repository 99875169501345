import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ClockIcon, UserIcon } from '../../components/icons';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';

const BlogShow = () => {
      const { blogId } = useParams();
      const [blog, setBlog] = useState([]);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs/${blogId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlog(response.data.blog);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Blog Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/blogs"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <img
                                                            src={`${blog.image}`}
                                                            className="w-100"
                                                      />
                                                      <div className="d-flex align-items-center gap-3 p-3">
                                                            <div className="d-flex align-items-center gap-2">
                                                                  <UserIcon />
                                                                  <span>
                                                                        {
                                                                              blog.user_name
                                                                        }
                                                                  </span>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-2">
                                                                  <ClockIcon />
                                                                  <span>
                                                                        {
                                                                              blog.date
                                                                        }
                                                                  </span>
                                                            </div>
                                                      </div>
                                                      <div className="px-3 ">
                                                            <h5>
                                                                  {blog.title}
                                                            </h5>
                                                            <div>
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: blog.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      </div>
                                                </Col>
                                                <Col lg={5}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        blog.category
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Title"
                                                                  value={
                                                                        blog.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Keyword"
                                                                  value={
                                                                        blog.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Description"
                                                                  value={
                                                                        blog.seo_description
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        blog.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>

                                                      <div className="p-3">
                                                            <img
                                                                  style={{
                                                                        width: '200px'
                                                                  }}
                                                                  src={
                                                                        blog.seo_image
                                                                  }
                                                                  alt={
                                                                        blog.title
                                                                  }
                                                            />
                                                      </div>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BlogShow;
