import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import $ from 'jquery';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSelect,
      FormSeo,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';

const options = [
      {
            id: 'Full Time',
            title: 'Full Time'
      },
      {
            id: 'Part Time',
            title: 'Part Time'
      }
];

const JobCreate = () => {
      const [slug, setSlug] = useState('');
      const [title, setTitle] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [salary, setSalary] = useState('');
      const [deadline, setDeadline] = useState('');
      const [education, setEducation] = useState('');
      const [experience, setExperience] = useState('');
      const [noOfVacancy, setNoOfVacancy] = useState('');
      const [type, setType] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [description, setDescription] = useState('');
      const [summary, setSummary] = useState('');
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [department, setDepartment] = useState('');

      const [loadingData, setLoadingData] = useState(false);
      const [departments, setDepartments] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/department/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDepartments(response.data.departments);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  salary: salary,
                  deadline: deadline,
                  education: education,
                  experience: experience,
                  no_of_vacancy: noOfVacancy,
                  summary: summary,
                  description: description,
                  type: type,
                  status: status,
                  image: image,
                  seo_title: seoTitle,
                  seo_image: seoImage,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription,
                  department: department
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl(null);
                              setSeoImageUrl(null);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.no_of_vacancy) {
                                    toast.error(
                                          response.data.message.no_of_vacancy
                                    );
                              }
                              if (response.data.message.experience) {
                                    toast.error(
                                          response.data.message.experience
                                    );
                              }
                              if (response.data.message.education) {
                                    toast.error(
                                          response.data.message.education
                                    );
                              }
                              if (response.data.message.deadline) {
                                    toast.error(response.data.message.deadline);
                              }
                              if (response.data.message.salary) {
                                    toast.error(response.data.message.salary);
                              }
                              if (response.data.message.department) {
                                    toast.error(
                                          response.data.message.department
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create New Job">
                                                <LinkButton
                                                      link="/dashboard/jobs"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            classes={8}
                                                            title="Title"
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Slug"
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Department"
                                                            options={
                                                                  departments
                                                            }
                                                            required={true}
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            setData={
                                                                  setDepartment
                                                            }
                                                      />
                                                      <FormSelect
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Job Type"
                                                            required={true}
                                                            options={options}
                                                            setData={setType}
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Education"
                                                            required={true}
                                                            handleChange={
                                                                  setEducation
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Experience"
                                                            required={true}
                                                            handleChange={
                                                                  setExperience
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="No. of Vacancy"
                                                            required={true}
                                                            handleChange={
                                                                  setNoOfVacancy
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Salary"
                                                            required={true}
                                                            handleChange={
                                                                  setSalary
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Deadline"
                                                            required={true}
                                                            type="date"
                                                            handleChange={
                                                                  setDeadline
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            classes={4}
                                                            title="Status"
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Summary"
                                                            required={true}
                                                            handleChange={
                                                                  setSummary
                                                            }
                                                      />
                                                      <FormDescription
                                                            title="Description"
                                                            required={true}
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={false}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default JobCreate;
