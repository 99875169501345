import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import 'react-nestable/dist/styles/index.css';
import { useParams } from 'react-router-dom';

const ProductOrder = () => {
      const { areaId } = useParams();

      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10000);
      const [orders, setOrders] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/areas/${areaId}/edit?limit=${limit}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setProducts(response.data.products);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit]);

      const handleDragStart = (e, index) => {
            e.dataTransfer.setData('index', index.toString());
      };

      const handleDragOver = async (e, order) => {
            e.preventDefault();
            setOrders(order);
      };

      const handleDrop = async (e, dropIndex) => {
            const dragIndex = parseInt(e.dataTransfer.getData('index'));
            const draggedProduct = products[dragIndex];

            const updatedProducts = products.filter(
                  (item, index) => index !== dragIndex
            );

            updatedProducts.splice(dropIndex, 0, draggedProduct);

            setProducts(updatedProducts);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/orders/change`,
                        {
                              products: updatedProducts
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        console.log(response.data);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        {!loading ? (
                              products?.map((item, index) => (
                                    <Col lg={4}>
                                          <div
                                                className="draggable-row draggable-item"
                                                key={index}
                                                draggable="true"
                                                onDragStart={(e) =>
                                                      handleDragStart(e, index)
                                                }
                                                onDragOver={(e) =>
                                                      handleDragOver(
                                                            e,
                                                            products
                                                      )
                                                }
                                                onDrop={(e) =>
                                                      handleDrop(e, index)
                                                }
                                          >
                                                <div className="d-flex align-items-center">
                                                      <img
                                                            src={item.image}
                                                            alt=""
                                                      />
                                                </div>
                                                <div className="text-start">
                                                      <div>{item.title}</div>
                                                      <div className="text-muted">
                                                            Category:{' '}
                                                            {item.category}
                                                      </div>
                                                      <div className="text-muted">
                                                            Size: {item.size}
                                                      </div>
                                                </div>
                                          </div>
                                    </Col>
                              ))
                        ) : (
                              <div className="text-center my-5 py-5">
                                    <Spinner />
                              </div>
                        )}
                  </Row>
            </>
      );
};

export default ProductOrder;
