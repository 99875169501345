import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import './buttonStyle.css';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSelect,
      FormSeo,
      FormStatusSelect
} from '../../components/form';

const ProductCreate = () => {
      const [title, setTitle] = useState('');
      const [description, setDescription] = useState('');
      const [stock, setStock] = useState('');
      const [price, setPrice] = useState('');
      const [category, setCategory] = useState('');
      const [color, setColor] = useState('');
      const [unit, setUnit] = useState('');
      const [finish, setFinish] = useState('');
      const [type, setType] = useState('');
      const [area, setArea] = useState('');
      const [design, setDesign] = useState('');
      const [size, setSize] = useState('');
      const [collection, setCollection] = useState('');
      const [region, setRegion] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [slug, setSlug] = useState('');
      const [isLoading, setIsLoading] = useState(false);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const [areas, setAreas] = useState([]);
      const [categories, setCategories] = useState([]);
      const [types, setTypes] = useState([]);
      const [units, setUnits] = useState([]);
      const [sizes, setSizes] = useState([]);
      const [designs, setDesigns] = useState([]);
      const [finishes, setFinishes] = useState([]);
      const [colors, setColors] = useState([]);
      const [collections, setCollections] = useState([]);
      const [regions, setRegions] = useState([]);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  description: description,
                  stock: stock,
                  price: price,
                  category: category,
                  color: color,
                  unit: unit,
                  finish: finish,
                  type: type,
                  area: area,
                  design: design,
                  size: size,
                  collection: collection,
                  region: region,
                  image: image,
                  status: status,
                  seo_title: seoTitle,
                  seo_image: seoImage,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setImageUrl('');
                              setSeoImageUrl('');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.stock) {
                                    toast.error(response.data.message.stock);
                              }
                              if (response.data.message.price) {
                                    toast.error(response.data.message.price);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.color) {
                                    toast.error(response.data.message.color);
                              }
                              if (response.data.message.unit) {
                                    toast.error(response.data.message.unit);
                              }
                              if (response.data.message.finish) {
                                    toast.error(response.data.message.finish);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.area) {
                                    toast.error(response.data.message.area);
                              }
                              if (response.data.message.design) {
                                    toast.error(response.data.message.design);
                              }
                              if (response.data.message.size) {
                                    toast.error(response.data.message.size);
                              }
                              if (response.data.message.collection) {
                                    toast.error(
                                          response.data.message.collection
                                    );
                              }
                              if (response.data.message.region) {
                                    toast.error(response.data.message.region);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_image) {
                                    toast.error(
                                          response.data.message.seo_image
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      const loadData = async () => {
            setIsLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/extra/data`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                        setDesigns(response.data.designs);
                        setAreas(response.data.areas);
                        setTypes(response.data.types);
                        setUnits(response.data.units);
                        setSizes(response.data.sizes);
                        setFinishes(response.data.finishes);
                        setColors(response.data.colors);
                        setCollections(response.data.collections);
                        setRegions(response.data.regions);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setIsLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row className="gap-3">
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create Product">
                                                <LinkButton
                                                      link="/dashboard/products"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            required={true}
                                                            classes={8}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Slug"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Unit"
                                                            classes={4}
                                                            options={units}
                                                            loading={isLoading}
                                                            setData={setUnit}
                                                      />
                                                      <FormInput
                                                            title="Price"
                                                            classes={4}
                                                            handleChange={
                                                                  setPrice
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Stock"
                                                            classes={4}
                                                            handleChange={
                                                                  setStock
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            required={true}
                                                            classes={4}
                                                            options={categories}
                                                            loading={isLoading}
                                                            setData={
                                                                  setCategory
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Size"
                                                            required={true}
                                                            classes={4}
                                                            options={sizes}
                                                            loading={isLoading}
                                                            setData={setSize}
                                                      />
                                                      <FormSelect
                                                            title="Color"
                                                            required={true}
                                                            classes={4}
                                                            options={colors}
                                                            loading={isLoading}
                                                            setData={setColor}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                              <Col lg={12}>
                                    <Card>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormSelect
                                                            title="Area"
                                                            classes={4}
                                                            options={areas}
                                                            loading={isLoading}
                                                            setData={setArea}
                                                      />
                                                      <FormSelect
                                                            title="Type"
                                                            classes={4}
                                                            options={types}
                                                            loading={isLoading}
                                                            setData={setType}
                                                      />
                                                      <FormSelect
                                                            title="Design"
                                                            classes={4}
                                                            options={designs}
                                                            loading={isLoading}
                                                            setData={setDesign}
                                                      />
                                                      <FormSelect
                                                            title="Finish"
                                                            classes={4}
                                                            options={finishes}
                                                            loading={isLoading}
                                                            setData={setFinish}
                                                      />
                                                      <FormSelect
                                                            title="Collection"
                                                            classes={4}
                                                            options={
                                                                  collections
                                                            }
                                                            loading={isLoading}
                                                            setData={
                                                                  setCollection
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Region"
                                                            classes={4}
                                                            options={regions}
                                                            loading={isLoading}
                                                            setData={setRegion}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                              <Col lg={12}>
                                    <Card>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormDescription
                                                            title="Description"
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProductCreate;
