import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import {
      CareerIcon,
      CheckListIcon,
      ComplaintIcon,
      ElementIcon
} from '../../dashboard';

const WebsiteManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'banner-list',
                              'banner-edit',
                              'banner-create',
                              'banner-show',
                              'plugin-list',
                              'plugin-create',
                              'plugin-edit',
                              'plugin-show',
                              'testimonial-list',
                              'testimonial-create',
                              'testimonial-edit',
                              'testimonial-show',
                              'job-list',
                              'job-edit',
                              'job-create',
                              'job-show',
                              'job-department-list',
                              'job-department-edit',
                              'job-department-create',
                              'job-department-delete',
                              'complain-list',
                              'complain-show',
                              'complain-type-list',
                              'complain-type-show',
                              'complain-type-edit',
                              'complain-type-create',
                              'candidate-list',
                              'candidate-show',
                              'subscriber-list',
                              'contact-request-list',
                              'contact-request-show',
                              'client-list',
                              'client-create',
                              'client-edit',
                              'client-show'
                        ]}
                  >
                        <MenuSeparator>Website Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'banner-list',
                                    'banner-edit',
                                    'banner-create',
                                    'banner-show',
                                    'client-list',
                                    'client-create',
                                    'client-edit',
                                    'client-show',
                                    'plugin-list',
                                    'plugin-create',
                                    'plugin-edit',
                                    'plugin-show',
                                    'testimonial-list',
                                    'testimonial-create',
                                    'testimonial-edit',
                                    'testimonial-show'
                              ]}
                        >
                              <MenuItem
                                    title="Element Manage"
                                    icon={<ElementIcon />}
                                    links={[
                                          '/dashboard/banners',
                                          '/dashboard/banners/create',
                                          '/dashboard/banners/:bannerId/edit',
                                          '/dashboard/banners/:bannerId/show',
                                          '/dashboard/clients',
                                          '/dashboard/clients/create',
                                          '/dashboard/clients/:clientId/edit',
                                          '/dashboard/clients/:clientId/show',
                                          '/dashboard/plugins',
                                          '/dashboard/plugins/create',
                                          '/dashboard/plugins/:pluginId/edit',
                                          '/dashboard/plugins/:pluginId/show',
                                          '/dashboard/testimonials',
                                          '/dashboard/testimonials/create',
                                          '/dashboard/testimonials/:testimonialId/edit',
                                          '/dashboard/testimonials/:testimonialId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'client-list',
                                                'client-edit',
                                                'client-create',
                                                'client-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Client"
                                                link="/dashboard/clients"
                                                links={[
                                                      '/dashboard/clients',
                                                      '/dashboard/clients/create',
                                                      '/dashboard/clients/:clientId/edit',
                                                      '/dashboard/clients/:clientId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'banner-list',
                                                'banner-edit',
                                                'banner-create',
                                                'banner-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Banner"
                                                link="/dashboard/banners"
                                                links={[
                                                      '/dashboard/banners',
                                                      '/dashboard/banners/create',
                                                      '/dashboard/banners/:bannerId/edit',
                                                      '/dashboard/banners/:bannerId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'plugin-list',
                                                'plugin-create',
                                                'plugin-edit',
                                                'plugin-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Plugins"
                                                link="/dashboard/plugins"
                                                links={[
                                                      '/dashboard/plugins',
                                                      '/dashboard/plugins/create',
                                                      '/dashboard/plugins/:pluginId/edit',
                                                      '/dashboard/plugins/:pluginId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'testimonial-list',
                                                'testimonial-create',
                                                'testimonial-edit',
                                                'testimonial-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Testimonial"
                                                link="/dashboard/testimonials"
                                                links={[
                                                      '/dashboard/testimonials',
                                                      '/dashboard/testimonials/create',
                                                      '/dashboard/testimonials/:testimonialId/edit',
                                                      '/dashboard/testimonials/:testimonialId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'job-list',
                                    'job-edit',
                                    'job-create',
                                    'job-show',
                                    'job-department-list',
                                    'job-department-edit',
                                    'job-department-create',
                                    'job-department-delete',
                                    'candidate-list',
                                    'candidate-show'
                              ]}
                        >
                              <MenuItem
                                    title="Career Manage"
                                    icon={<CareerIcon />}
                                    links={[
                                          '/dashboard/jobs',
                                          '/dashboard/jobs/create',
                                          '/dashboard/jobs/:jobId/edit',
                                          '/dashboard/jobs/:jobId/show',
                                          '/dashboard/job_departments',
                                          '/dashboard/job_departments/create',
                                          '/dashboard/job_departments/:departmentId/edit',
                                          '/dashboard/job_departments/:departmentId/show',
                                          '/dashboard/candidates',
                                          '/dashboard/candidates/create',
                                          '/dashboard/candidates/:candidateId/edit',
                                          '/dashboard/candidates/:candidateId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'job-list',
                                                'job-create',
                                                'job-edit',
                                                'job-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="All Job"
                                                link="/dashboard/jobs"
                                                links={[
                                                      '/dashboard/jobs',
                                                      '/dashboard/jobs/create',
                                                      '/dashboard/jobs/:jobId/edit',
                                                      '/dashboard/jobs/:jobId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'job-department-list',
                                                'job-department-create',
                                                'job-department-edit',
                                                'job-department-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Department List"
                                                link="/dashboard/job_departments"
                                                links={[
                                                      '/dashboard/job_departments',
                                                      '/dashboard/job_departments/create',
                                                      '/dashboard/job_departments/:departmentId/edit',
                                                      '/dashboard/job_departments/:departmentId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'candidate-list',
                                                'candidate-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Candidate List"
                                                link="/dashboard/candidates"
                                                links={[
                                                      '/dashboard/candidates',
                                                      '/dashboard/candidates/create',
                                                      '/dashboard/candidates/:candidateId/edit',
                                                      '/dashboard/candidates/:candidateId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'complain-list',
                                    'complain-show',
                                    'complain-type-list',
                                    'complain-type-show',
                                    'complain-type-edit',
                                    'complain-type-create'
                              ]}
                        >
                              <MenuItem
                                    title="Complaint Manage"
                                    icon={<ComplaintIcon />}
                                    links={[
                                          '/dashboard/complains',
                                          '/dashboard/complains/create',
                                          '/dashboard/complains/:complainId/edit',
                                          '/dashboard/complains/:complainId/show',
                                          '/dashboard/complains/types',
                                          '/dashboard/complains/types/create',
                                          '/dashboard/complains/types/:typeId/edit',
                                          '/dashboard/complains/types/:typeId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'complain-list',
                                                'complain-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="All Complaint"
                                                link="/dashboard/complains"
                                                links={[
                                                      '/dashboard/complains',
                                                      '/dashboard/complains/create',
                                                      '/dashboard/complains/:complainId/edit',
                                                      '/dashboard/complains/:complainId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'complain-type-list',
                                                'complain-type-show',
                                                'complain-type-edit',
                                                'complain-type-create'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Complaint Type"
                                                link="/dashboard/complains/types"
                                                links={[
                                                      '/dashboard/complains/types',
                                                      '/dashboard/complains/types/create',
                                                      '/dashboard/complains/types/:typeId/edit',
                                                      '/dashboard/complains/types/:typeId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'subscriber-list',
                                    'contact-request-list',
                                    'contact-request-show'
                              ]}
                        >
                              <MenuItem
                                    title="Frontend Form"
                                    icon={<CheckListIcon />}
                                    links={[
                                          '/dashboard/subscribers',
                                          '/dashboard/subscribers/create',
                                          '/dashboard/subscribers/:subscriberId/edit',
                                          '/dashboard/subscribers/:subscriberId/show',
                                          '/dashboard/contact/requests',
                                          '/dashboard/contact/requests/create',
                                          '/dashboard/contact/requests/:contactId/edit',
                                          '/dashboard/contact/requests/:contactId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={['subscriber-list']}
                                    >
                                          <MenuLink
                                                title="Subscriber List"
                                                link="/dashboard/subscribers"
                                                links={[
                                                      '/dashboard/subscribers',
                                                      '/dashboard/subscribers/create',
                                                      '/dashboard/subscribers/:subscriberId/edit',
                                                      '/dashboard/subscribers/:subscriberId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'contact-request-list',
                                                'contact-request-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Contact Request"
                                                link="/dashboard/contact/requests"
                                                links={[
                                                      '/dashboard/contact/requests',
                                                      '/dashboard/contact/requests/create',
                                                      '/dashboard/contact/requests/:contactId/edit',
                                                      '/dashboard/contact/requests/:contactId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default WebsiteManage;
