import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import LinkButton from '../../components/common/LinkButton';
import EditButton from '../../components/common/EditButton';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';
import ProductImage from '../../components/common/ProductImage';

const TestimonialList = () => {
      const [loading, setLoading] = useState(false);
      const [testimonials, setTestimonials] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchTestimonials = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        setTestimonials(response.data.testimonials);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            fetchTestimonials();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials(response.data.testimonials);
                              setTotalItems(response.data.totalItems);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (testimonial) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonial.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (testimonial) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonial.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials((testimonials) =>
                                    testimonials.filter(
                                          (item) => item.id !== testimonial.id
                                    )
                              );
                              fetchTestimonials();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col className="col-md-12">
                              <Card className="card">
                                    <CardHeader title="Testimonial List">
                                          <LinkButton
                                                link="/dashboard/testimonials/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          perPages={perPages}
                                          totalItems={totalItems}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                    >
                                          <thead>
                                                <tr>
                                                      <th
                                                            width="80px"
                                                            className="text-center"
                                                      >
                                                            #
                                                      </th>
                                                      <th>Full Name</th>
                                                      <th>Company</th>
                                                      <th>Date</th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      testimonials.map(
                                                            (
                                                                  testimonial,
                                                                  index
                                                            ) => (
                                                                  <>
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <td className="text-center">
                                                                                    {
                                                                                          ++index
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <ProductImage
                                                                                          title={
                                                                                                testimonial.full_name
                                                                                          }
                                                                                          image={
                                                                                                testimonial.image
                                                                                          }
                                                                                          category={`By: ${testimonial.user_name}`}
                                                                                    />
                                                                              </td>
                                                                              <td>
                                                                                    <ProductImage
                                                                                          title={
                                                                                                testimonial.company_name
                                                                                          }
                                                                                          category={`Position: ${testimonial.position}`}
                                                                                    />
                                                                              </td>
                                                                              <td>
                                                                                    {
                                                                                          testimonial.date
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <Form.Check
                                                                                          type="switch"
                                                                                          id="custom-switch"
                                                                                          defaultChecked={
                                                                                                testimonial.status ===
                                                                                                1
                                                                                                      ? true
                                                                                                      : false
                                                                                          }
                                                                                          onClick={() =>
                                                                                                handleStatus(
                                                                                                      testimonial
                                                                                                )
                                                                                          }
                                                                                    />
                                                                              </td>
                                                                              <td className="text-center">
                                                                                    <EditButton
                                                                                          link={`/dashboard/testimonials/${testimonial.slug}/edit`}
                                                                                    />
                                                                                    <ViewButton
                                                                                          link={`/dashboard/testimonials/${testimonial.slug}/show`}
                                                                                    />
                                                                                    <DeleteButton
                                                                                          item={
                                                                                                testimonial
                                                                                          }
                                                                                          loading={
                                                                                                loading
                                                                                          }
                                                                                          setLoading={
                                                                                                setLoading
                                                                                          }
                                                                                          handleDelete={
                                                                                                handleDelete
                                                                                          }
                                                                                    />
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              7
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No
                                                                        Testimonial
                                                                        Found
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default TestimonialList;
