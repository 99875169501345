import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSelect,
      FormSeo,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';
import LinkButton from '../../components/common/LinkButton';

const options = [
      {
            id: 'Full Time',
            title: 'Full Time'
      },
      {
            id: 'Part Time',
            title: 'Part Time'
      }
];

const JobEdit = () => {
      const { jobId } = useParams();
      const [loadingData, setLoadingData] = useState(false);
      const [departments, setDepartments] = useState([]);

      const [job, setJob] = useState('');
      const [slug, setSlug] = useState('');
      const [title, setTitle] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [salary, setSalary] = useState('');
      const [deadline, setDeadline] = useState('');
      const [education, setEducation] = useState('');
      const [experience, setExperience] = useState('');
      const [noOfVacancy, setNoOfVacancy] = useState('');
      const [type, setType] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [description, setDescription] = useState('');
      const [summary, setSummary] = useState('');
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [department, setDepartment] = useState('');

      const token = JSON.parse(localStorage.getItem('token'));
      const navigate = useNavigate();

      const loadData = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${jobId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setJob(response.data.job);
                              setSlug(response.data.job.slug);
                              setTitle(response.data.job.title);
                              setSalary(response.data.job.salary);
                              setDeadline(response.data.job.deadline_date);
                              setEducation(response.data.job.education);
                              setExperience(response.data.job.experience);
                              setNoOfVacancy(response.data.job.no_of_vacancy);
                              setType(response.data.job.type);
                              setSummary(response.data.job.summary);
                              setDescription(response.data.job.description);
                              setStatus(response.data.job.status);
                              setImageUrl(response.data.job.image);
                              setSeoTitle(response.data.job.seo_title);
                              setSeoDescription(
                                    response.data.job.seo_description
                              );
                              setSeoImageUrl(response.data.job.seo_image);
                              setSeoKeyword(response.data.job.seo_keyword);
                              setDepartment(response.data.job.department_id);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/department/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDepartments(response.data.departments);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  salary: salary,
                  deadline: deadline,
                  education: education,
                  experience: experience,
                  no_of_vacancy: noOfVacancy,
                  summary: summary,
                  description: description,
                  type: type,
                  status: status,
                  image: image,
                  seo_title: seoTitle,
                  seo_image: seoImage,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription,
                  department: department
            };

            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/jobs/${updatedSlug}/edit`);
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/job_lists/${jobId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);

                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.no_of_vacancy) {
                                    toast.error(
                                          response.data.message.no_of_vacancy
                                    );
                              }
                              if (response.data.message.experience) {
                                    toast.error(
                                          response.data.message.experience
                                    );
                              }
                              if (response.data.message.education) {
                                    toast.error(
                                          response.data.message.education
                                    );
                              }
                              if (response.data.message.deadline) {
                                    toast.error(response.data.message.deadline);
                              }
                              if (response.data.message.salary) {
                                    toast.error(response.data.message.salary);
                              }
                              if (response.data.message.department) {
                                    toast.error(
                                          response.data.message.department
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Details">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/jobs"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            classes={8}
                                                            title="Title"
                                                            required={true}
                                                            value={title}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Slug"
                                                            value={slug}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormSelect
                                                            classes={4}
                                                            title="Department"
                                                            loading={
                                                                  loadingData
                                                            }
                                                            options={departments}
                                                            setData={setDepartment}
                                                            selected={department}
                                                      />
                                                      <FormSelect
                                                            classes={4}
                                                            loading={false}
                                                            selected={type}
                                                            title="Job Type"
                                                            required={true}
                                                            options={options}
                                                            setData={setType}
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Education"
                                                            value={education}
                                                            required={true}
                                                            handleChange={
                                                                  setEducation
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Experience"
                                                            value={experience}
                                                            required={true}
                                                            handleChange={
                                                                  setExperience
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="No. of Vacancy"
                                                            value={noOfVacancy}
                                                            required={true}
                                                            handleChange={
                                                                  setNoOfVacancy
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Salary"
                                                            value={salary}
                                                            required={true}
                                                            handleChange={
                                                                  setSalary
                                                            }
                                                      />
                                                      <FormInput
                                                            classes={4}
                                                            title="Deadline"
                                                            value={deadline}
                                                            required={true}
                                                            type="date"
                                                            handleChange={
                                                                  setDeadline
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            classes={4}
                                                            title="Status"
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Summary"
                                                            value={summary}
                                                            required={false}
                                                            handleChange={
                                                                  setSummary
                                                            }
                                                      />
                                                      <FormDescription
                                                            title="Description"
                                                            description={
                                                                  description
                                                            }
                                                            required={true}
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={false}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          title={seoTitle}
                                          keyword={seoKeyword}
                                          description={seoDescription}
                                    />
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default JobEdit;
