import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { ItemShow } from '../../../components/form';
import CardHeader from '../../../components/common/CardHeader';
import LinkButton from '../../../components/common/LinkButton';

const DealerContactShow = () => {
      const { contactId } = useParams();
      const [contact, setContact] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/dealer_contacts/${contactId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContact(response.data.dealer);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Dealer Contact Detail">
                                          <LinkButton
                                                link="/dashboard/dealer/contacts"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                {contact?.image && (
                                                      <Col lg={4}>
                                                            <Card>
                                                                  <Card.Body className="text-center">
                                                                        <img
                                                                              className="w-100"
                                                                              src={`${contact.image}`}
                                                                        />
                                                                  </Card.Body>
                                                            </Card>
                                                      </Col>
                                                )}
                                                <Col lg="7">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Full Name"
                                                                  value={
                                                                        contact.full_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Store Title"
                                                                  value={
                                                                        contact.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Phone No"
                                                                  value={
                                                                        contact.phone
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email Address"
                                                                  value={
                                                                        contact.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Province"
                                                                  value={
                                                                        contact.province
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="District"
                                                                  value={
                                                                        contact.district
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Zone"
                                                                  value={
                                                                        contact.zone
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Address"
                                                                  value={
                                                                        contact.address
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By: "
                                                                  value={
                                                                        contact.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date "
                                                                  value={
                                                                        contact.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        contact.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={5}>
                                                      <iframe
                                                            src={
                                                                  contact.location
                                                            }
                                                            width="100%"
                                                            height="320"
                                                            allowfullscreen=""
                                                            loading="lazy"
                                                            referrerpolicy="no-referrer-when-downgrade"
                                                      ></iframe>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DealerContactShow;
