import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import FolderForm from '../../components/form/folders/FolderForm';
import Skeleton from 'react-loading-skeleton';

const FolderEdit = () => {
      const { folderId } = useParams();

      const [folder, setFolder] = useState([]);
      const [title, setTitle] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [validated, setValidated] = useState(false);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  await axios
                        .get(
                              `http://127.0.0.1:8000/api/dashboard/folders/${folderId}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${JSON.parse(
                                                localStorage.getItem('token')
                                          )}`,
                                          'Content-Type': 'multipart/form-data'
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setFolder(response.data.folder);
                                    setTitle(response.data.folder.title);
                                    setStatus(response.data.folder.status);
                              }
                              setLoading(false);
                        });
            };

            fetchData();
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                  event.stopPropagation();
            }

            setValidated(true);

            const data = {
                  title: title ? title : folder.title,
                  status: status ? status : folder.status
            };
            await axios
                  .post(
                        `http://127.0.0.1:8000/api/dashboard/folders/${folderId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>Edit Folder</Card.Title>
                                          <Link
                                                to="/dashboard/folders"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          {!loading ? (
                                                <FolderForm
                                                      validated={validated}
                                                      handleFormSubmit={
                                                            handleFormSubmit
                                                      }
                                                      setTitle={setTitle}
                                                      setStatus={setStatus}
                                                      title={title}
                                                      status={status}
                                                />
                                          ) : (
                                                <>
                                                      <Row>
                                                            <Col>
                                                                  <Skeleton
                                                                        height={
                                                                              35
                                                                        }
                                                                  />
                                                            </Col>
                                                            <Col>
                                                                  <Skeleton
                                                                        height={
                                                                              35
                                                                        }
                                                                  />
                                                            </Col>
                                                      </Row>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FolderEdit;
