import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Progress from 'react-progress-2';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import CardBody from '../../components/common/CardBody';
import ProductImage from '../../components/common/ProductImage';
import EditButton from '../../components/common/EditButton';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';

const TypeList = () => {
      const [types, setTypes] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/types?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setTypes(response.data.types);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/types?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setTypes([]);
                        setTypes(response.data.types);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (type) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/types/${type.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleMenu = async (type) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/types/${type.slug}/menu`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (type) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/types/${type.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTypes((types) =>
                                    types.filter((item) => item.id !== type.id)
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Type List">
                                          <LinkButton
                                                link="/dashboard/types/create"
                                                title="Add New"
                                          />
                                          <LinkButton
                                                link="/dashboard/products"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          totalItems={totalItems}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                          getData={getData}
                                    >
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>Name</th>
                                                      <th>Products</th>
                                                      <th>Date</th>
                                                      <th>Home Menu</th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="100px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      types?.length > 0 ? (
                                                            types.map(
                                                                  (
                                                                        type,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                type.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      type.title
                                                                                                }
                                                                                                image={
                                                                                                      type.image
                                                                                                }
                                                                                                category={`By: ${type.user_name}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                type.products
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                type.date
                                                                                          }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      type.navigation_status ===
                                                                                                      1
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      handleMenu(
                                                                                                            type
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      type.status ===
                                                                                                      1
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      handleStatus(
                                                                                                            type
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/types/${type.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/types/${type.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      type
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    7
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Type
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              7
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default TypeList;
