import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import { ProductIcon } from '../../dashboard';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'product-list',
                              'product-create',
                              'product-edit',
                              'product-show',
                              'category-list',
                              'category-create',
                              'category-edit',
                              'category-show',
                              'unit-list',
                              'unit-create',
                              'unit-show',
                              'unit-edit',
                              'area-list',
                              'area-create',
                              'area-edit',
                              'area-show',
                              'type-list',
                              'type-create',
                              'type-edit',
                              'type-show',
                              'size-list',
                              'size-create',
                              'size-edit',
                              'size-show',
                              'design-list',
                              'design-create',
                              'design-edit',
                              'design-show',
                              'finish-list',
                              'finish-create',
                              'finish-edit',
                              'finish-show',
                              'color-list',
                              'color-create',
                              'color-edit',
                              'color-show',
                              'collection-list',
                              'collection-create',
                              'collection-edit',
                              'collection-show',
                              'region-list',
                              'region-create',
                              'region-edit',
                              'region-show'
                        ]}
                  >
                        <MenuSeparator>product Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'product-list',
                                    'product-create',
                                    'product-edit',
                                    'product-show',
                                    'category-list',
                                    'category-create',
                                    'category-edit',
                                    'category-show',
                                    'unit-list',
                                    'unit-create',
                                    'unit-show',
                                    'unit-edit',
                                    'area-list',
                                    'area-create',
                                    'area-edit',
                                    'area-show',
                                    'type-list',
                                    'type-create',
                                    'type-edit',
                                    'type-show',
                                    'size-list',
                                    'size-create',
                                    'size-edit',
                                    'size-show',
                                    'design-list',
                                    'design-create',
                                    'design-edit',
                                    'design-show',
                                    'finish-list',
                                    'finish-create',
                                    'finish-edit',
                                    'finish-show',
                                    'color-list',
                                    'color-create',
                                    'color-edit',
                                    'color-show',
                                    'collection-list',
                                    'collection-create',
                                    'collection-edit',
                                    'collection-show',
                                    'region-list',
                                    'region-create',
                                    'region-edit',
                                    'region-show'
                              ]}
                        >
                              <MenuItem
                                    title="Products"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/products',
                                          '/dashboard/products/create',
                                          '/dashboard/products/:productId/edit',
                                          '/dashboard/products/:productId/show',
                                          '/dashboard/areas',
                                          '/dashboard/areas/create',
                                          '/dashboard/areas/:areaId/edit',
                                          '/dashboard/areas/:areaId/show',
                                          '/dashboard/units',
                                          '/dashboard/units/create',
                                          '/dashboard/units/:unitId/edit',
                                          '/dashboard/units/:unitId/show',
                                          '/dashboard/types',
                                          '/dashboard/types/create',
                                          '/dashboard/types/:typeId/edit',
                                          '/dashboard/types/:typeId/show',
                                          '/dashboard/sizes',
                                          '/dashboard/sizes/create',
                                          '/dashboard/sizes/:sizeId/edit',
                                          '/dashboard/sizes/:sizeId/show',
                                          '/dashboard/desgigns',
                                          '/dashboard/desgigns/create',
                                          '/dashboard/desgigns/:designId/edit',
                                          '/dashboard/desgigns/:designId/show',
                                          '/dashboard/finishes',
                                          '/dashboard/finishes/create',
                                          '/dashboard/finishes/:finishId/edit',
                                          '/dashboard/finishes/:finishId/show',
                                          '/dashboard/colors',
                                          '/dashboard/colors/create',
                                          '/dashboard/colors/:colorId/edit',
                                          '/dashboard/colors/:colorId/show',
                                          '/dashboard/collections',
                                          '/dashboard/collections/create',
                                          '/dashboard/collections/:collectionId/edit',
                                          '/dashboard/collections/:collectionId/show',
                                          '/dashboard/regions',
                                          '/dashboard/regions/create',
                                          '/dashboard/regions/:regionId/edit',
                                          '/dashboard/regions/:regionId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'category-list',
                                                'category-create',
                                                'category-edit',
                                                'category-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category"
                                                link="/dashboard/categories"
                                                links={[
                                                      '/dashboard/categories',
                                                      '/dashboard/categories/create',
                                                      '/dashboard/categories/:categoryId/edit',
                                                      '/dashboard/categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'product-list',
                                                'product-create',
                                                'product-edit',
                                                'product-show',
                                                'product-size-list',
                                                'product-size-create',
                                                'product-size-edit',
                                                'unit-list',
                                                'unit-create',
                                                'unit-show',
                                                'unit-edit',
                                                'area-list',
                                                'area-create',
                                                'area-edit',
                                                'area-show',
                                                'type-list',
                                                'type-create',
                                                'type-edit',
                                                'type-show',
                                                'size-list',
                                                'size-create',
                                                'size-edit',
                                                'size-show',
                                                'design-list',
                                                'design-create',
                                                'design-edit',
                                                'design-show',
                                                'finish-list',
                                                'finish-create',
                                                'finish-edit',
                                                'finish-show',
                                                'color-list',
                                                'color-create',
                                                'color-edit',
                                                'color-show',
                                                'collection-list',
                                                'collection-create',
                                                'collection-edit',
                                                'collection-show',
                                                'region-list',
                                                'region-create',
                                                'region-edit',
                                                'region-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/products"
                                                links={[
                                                      '/dashboard/products',
                                                      '/dashboard/products/create',
                                                      '/dashboard/products/:productId/edit',
                                                      '/dashboard/products/:productId/show',
                                                      '/dashboard/areas',
                                                      '/dashboard/areas/create',
                                                      '/dashboard/areas/:areaId/edit',
                                                      '/dashboard/areas/:areaId/show',
                                                      '/dashboard/units',
                                                      '/dashboard/units/create',
                                                      '/dashboard/units/:unitId/edit',
                                                      '/dashboard/units/:unitId/show',
                                                      '/dashboard/types',
                                                      '/dashboard/types/create',
                                                      '/dashboard/types/:typeId/edit',
                                                      '/dashboard/types/:typeId/show',
                                                      '/dashboard/sizes',
                                                      '/dashboard/sizes/create',
                                                      '/dashboard/sizes/:sizeId/edit',
                                                      '/dashboard/sizes/:sizeId/show',
                                                      '/dashboard/desgigns',
                                                      '/dashboard/desgigns/create',
                                                      '/dashboard/desgigns/:designId/edit',
                                                      '/dashboard/desgigns/:designId/show',
                                                      '/dashboard/finishes',
                                                      '/dashboard/finishes/create',
                                                      '/dashboard/finishes/:finishId/edit',
                                                      '/dashboard/finishes/:finishId/show',
                                                      '/dashboard/colors',
                                                      '/dashboard/colors/create',
                                                      '/dashboard/colors/:colorId/edit',
                                                      '/dashboard/colors/:colorId/show',
                                                      '/dashboard/collections',
                                                      '/dashboard/collections/create',
                                                      '/dashboard/collections/:collectionId/edit',
                                                      '/dashboard/collections/:collectionId/show',
                                                      '/dashboard/regions',
                                                      '/dashboard/regions/create',
                                                      '/dashboard/regions/:regionId/edit',
                                                      '/dashboard/regions/:regionId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
