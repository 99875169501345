import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Dropdown, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import ProductListBox from '../../components/backend/ProductListBox';

const ProductList = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setProducts([]);
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Product List">
                                          <div className="d-flex">
                                                <Link
                                                      to="/dashboard/products/create"
                                                      className="btn btn-link me-2"
                                                >
                                                      Add New
                                                </Link>
                                                <Dropdown>
                                                      <Dropdown.Toggle
                                                            variant="secondary"
                                                            className="btn btn-link"
                                                            id="dropdown-basic"
                                                      >
                                                            <i class="bx bx-menu-alt-right"></i>
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu className="product__menu">
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/areas"
                                                                        className="d-block"
                                                                  >
                                                                        Area
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/types"
                                                                        className="d-block"
                                                                  >
                                                                        Type
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/units"
                                                                        className="d-block"
                                                                  >
                                                                        Unit
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/sizes"
                                                                        className="d-block"
                                                                  >
                                                                        Size
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/designs"
                                                                        className="d-block"
                                                                  >
                                                                        Design
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/finishes"
                                                                        className="d-block"
                                                                  >
                                                                        Finish
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/colors"
                                                                        className="d-block"
                                                                  >
                                                                        Color
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/collections"
                                                                        className="d-block"
                                                                  >
                                                                        Collection
                                                                  </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="div">
                                                                  <Link
                                                                        to="/dashboard/regions"
                                                                        className="d-block"
                                                                  >
                                                                        Region
                                                                  </Link>
                                                            </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                </Dropdown>
                                          </div>
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          totalItems={totalProducts}
                                          getData={getData}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                    >
                                          <ProductListBox
                                                loading={loading}
                                                setLoading={setLoading}
                                                products={products}
                                                loadData={loadData}
                                                setProducts={setProducts}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductList;
