import React, { useState, useEffect } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import Progress from 'react-progress-2';
import ProductImage from '../../common/ProductImage';
import EditButton from '../../common/EditButton';
import ViewButton from '../../common/ViewButton';
import DeleteButton from '../../common/DeleteButton';
import { Form } from 'react-bootstrap';
import CardBody from '../../common/CardBody';

const DistrictListBox = ({ provinceId, zoneId }) => {
      const [loading, setLoading] = useState(false);
      const [districts, setDistricts] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);

            let url = '';
            if (provinceId) {
                  url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/provinces/${provinceId}/create?limit=${limit}&search=${search}`;
            } else if (zoneId) {
                  url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/zones/${zoneId}/edit?limit=${limit}&search=${search}`;
            } else {
                  url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/districts?limit=${limit}&search=${search}`;
            }

            await axios
                  .get(url, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`
                        }
                  })
                  .then((response) => {
                        setDistricts(response.data.districts);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            let url = '';
            if (provinceId) {
                  url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/provinces/${provinceId}/create?page=${pageNumber}&limit=${limit}&search=${search}`;
            } else if (zoneId) {
                  url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/zones/${zoneId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            } else {
                  url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/districts?page=${pageNumber}&limit=${limit}&search=${search}`;
            }
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setDistricts([]);
                        setDistricts(response.data.districts);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (zone) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/districts/${zone.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (zone) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/districts/${zone.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDistricts((districts) =>
                                    districts.filter(
                                          (item) => item.id !== zone.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <CardBody
                        setLimit={setLimit}
                        setSearch={setSearch}
                        currentPage={currentPage}
                        perPages={perPages}
                        totalItems={totalItems}
                        getData={getData}
                  >
                        <thead>
                              <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Province</th>
                                    <th>Zone</th>
                                    <th width="80px">Status</th>
                                    <th width="120px">Action</th>
                              </tr>
                        </thead>

                        <tbody>
                              {!loading ? (
                                    districts?.length > 0 ? (
                                          districts?.map((district, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>
                                                                  {
                                                                        district.index
                                                                  }
                                                            </td>
                                                            <td>
                                                                  <ProductImage
                                                                        title={
                                                                              district.title
                                                                        }
                                                                        category={`Category: ${district.category}`}
                                                                  />
                                                            </td>
                                                            <td>
                                                                  {
                                                                        district.province_name
                                                                  }
                                                            </td>
                                                            <td>
                                                                  {
                                                                        district.zone_name
                                                                  }
                                                            </td>
                                                            <td className="text-center">
                                                                  <Form.Check
                                                                        type="switch"
                                                                        defaultChecked={
                                                                              district.status ===
                                                                              1
                                                                                    ? true
                                                                                    : false
                                                                        }
                                                                        onClick={() =>
                                                                              handleStatus(
                                                                                    district
                                                                              )
                                                                        }
                                                                  />
                                                            </td>
                                                            <td className="text-center">
                                                                  <EditButton
                                                                        link={`/dashboard/districts/${district.slug}/edit`}
                                                                  />
                                                                  <ViewButton
                                                                        link={`/dashboard/districts/${district.slug}/show`}
                                                                  />
                                                                  <DeleteButton
                                                                        item={
                                                                              district
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        setLoading={
                                                                              setLoading
                                                                        }
                                                                        handleDelete={
                                                                              handleDelete
                                                                        }
                                                                  />
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr>
                                                      <td
                                                            colSpan={6}
                                                            className="text-center"
                                                      >
                                                            No District Found
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan={6}
                                                      className="text-center"
                                                >
                                                      Loading...
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </CardBody>
            </>
      );
};

export default DistrictListBox;
