import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

const ImageList = () => {
      const [images, setImages] = useState([]);
      const [folders, setFolders] = useState([]);
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  await axios
                        .get(`http://127.0.0.1:8000/api/dashboard/folders`, {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        })
                        .then((response) => {
                              setFolders(response.data.folders);
                              setLoading(false);
                        });
            };

            fetchData();
      }, []);

      return (
            <div className="row">
                  <div className="col-md-12">
                        <div className="card">
                              <div className="card-header">
                                    <div className="card-title">Image List</div>
                                    <Link
                                          to="/dashboard/images/create"
                                          className="btn btn-secondary"
                                    >
                                          Add New
                                    </Link>
                              </div>
                              <div className="card-body">
                                    {!loading ? (
                                          folders.length > 0 ? (
                                                <Row className="g-3">
                                                      {folders.map(
                                                            (folder, index) => (
                                                                  <>
                                                                        <div
                                                                              className="col-md-4"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Link
                                                                                    to={`/dashboard/images/${folder.slug}/folder`}
                                                                                    className=" d-flex align-items-center border px-3"
                                                                              >
                                                                                    <div>
                                                                                          <span
                                                                                                style={{
                                                                                                      lineHeight: 2
                                                                                                }}
                                                                                                class="material-symbols-outlined"
                                                                                          >
                                                                                                folder_open
                                                                                          </span>
                                                                                    </div>
                                                                                    <div className="ps-2">
                                                                                          {
                                                                                                folder.title
                                                                                          }
                                                                                    </div>
                                                                              </Link>
                                                                        </div>
                                                                  </>
                                                            )
                                                      )}
                                                </Row>
                                          ) : (
                                                <>
                                                      <Skeleton height={40} />
                                                </>
                                          )
                                    ) : (
                                          <>
                                                <Skeleton
                                                      height={40}
                                                      width={200}
                                                />
                                                <Skeleton
                                                      height={40}
                                                      width={200}
                                                />
                                          </>
                                    )}
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default ImageList;
