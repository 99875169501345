import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Progress from 'react-progress-2';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import CardBody from '../../components/common/CardBody';
import ProductListBox from '../../components/backend/ProductListBox';
import toast from 'react-hot-toast';

const AreaShow = () => {
      const { areaId } = useParams();
      const [area, setArea] = useState([]);

      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/areas/${areaId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  });
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            // console.log(pageNumber);
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/areas/${areaId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const loadArea = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/areas/${areaId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setArea(response.data.area);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadArea();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Area Detail">
                                          <LinkButton
                                                link="/dashboard/areas"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                {area.image && (
                                                      <Col lg={4}>
                                                            <Card>
                                                                  <Card.Body className="text-center">
                                                                        <img
                                                                              className="w-100"
                                                                              src={`${area.image}`}
                                                                        />
                                                                  </Card.Body>
                                                            </Card>
                                                      </Col>
                                                )}
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        area.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By: "
                                                                  value={
                                                                        area.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date "
                                                                  value={
                                                                        area.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        area.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ListGroup.Item>
                                                                  {' '}
                                                                  <strong>
                                                                        Description
                                                                        :{' '}
                                                                  </strong>{' '}
                                                                  <br />{' '}
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: area.description
                                                                        }}
                                                                  ></div>
                                                            </ListGroup.Item>
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>

                              <Card className="mt-4">
                                    <CardHeader title="Product List">
                                          <Link
                                                to={`/dashboard/products/${areaId}/order`}
                                                className="btn btn-link me-2"
                                          >
                                                Manage Order
                                          </Link>
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          totalItems={totalProducts}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                          setSearch={setSearch}
                                          getData={getData}
                                    >
                                          <ProductListBox
                                                loading={loading}
                                                products={products}
                                                loadData={loadData}
                                                setProducts={setProducts}
                                                setLoading={setLoading}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default AreaShow;
