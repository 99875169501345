import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Progress from 'react-progress-2';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import CardBody from '../../components/common/CardBody';
import ProductListBox from '../../components/backend/ProductListBox';

const UnitShow = () => {
      const { unitId } = useParams();
      const [unit, setUnit] = useState([]);

      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/units/${unitId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  });
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            // console.log(pageNumber);
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/units/${unitId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            }).then((response) => {
                  setProducts(response.data.products);
                  setTotalProducts(response.data.totalProducts);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);
                  Progress.hide();
            });
            setLoading(false);
      }

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/units/${unitId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            ).then((response) => {
                  if (response.data.result === 'success') {
                        setUnit(response.data.unit);
                  }
            });
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Unit Detail">
                                          <LinkButton
                                                link="/dashboard/units"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                {unit.image && (
                                                      <Col lg={4}>
                                                            <Card>
                                                                  <Card.Body className="text-center">
                                                                        <img
                                                                              className="w-100"
                                                                              src={`${unit.image}`}
                                                                        />
                                                                  </Card.Body>
                                                            </Card>
                                                      </Col>
                                                )}
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        unit.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By: "
                                                                  value={
                                                                        unit.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date "
                                                                  value={
                                                                        unit.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        unit.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ListGroup.Item>
                                                                  {' '}
                                                                  <strong>
                                                                        Description
                                                                        :{' '}
                                                                  </strong>{' '}
                                                                  <br />{' '}
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: unit.description
                                                                        }}
                                                                  ></div>
                                                            </ListGroup.Item>
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>

                              <Card className="mt-4">
                                    <CardHeader title="Product List" />
                                    <CardBody
                                          setLimit={setLimit}
                                          totalItems={totalProducts}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                          setSearch={setSearch}
                                          getData={getData}
                                    >
                                          <ProductListBox
                                                loading={loading}
                                                products={products}
                                                loadData={loadData}
                                                setProducts={setProducts}
                                                setLoading={setLoading}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default UnitShow;
