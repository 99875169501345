import React, { Suspense, useEffect } from 'react';

import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../frontend/style/style.css'; // Import Swiper styles
import $ from 'jquery';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Toaster } from 'react-hot-toast';
import ScrollToTop from '../ScrollToTop';
import Loader from '../Loader';

import 'dropzone/dist/dropzone.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../styles/themes';

import Progress from 'react-progress-2';
import PublicRouter from '../routers';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from '../frontend/auth/login';
import PrivateRouter from '../backend/layouts/PrivateLayout';
import './App.css';

function App() {
      return (
            <>
                  <Progress.Component
                        style={{ background: '#99999978', height: '5px' }}
                        thumbStyle={{
                              background: 'rgb(232, 107, 25)',
                              height: '5px'
                        }}
                  />
                  <ThemeProvider theme={lightTheme}>
                        <ScrollToTop />
                        <Toaster position="top-right" />
                        <Suspense fallback="Loading">
                              {useLocation().pathname === '/' ? (
                                    <Routes>
                                          <Route path="/" element={<Login />} />
                                    </Routes>
                              ) : (
                                    <PrivateRouter>
                                          <PublicRouter />
                                    </PrivateRouter>
                              )}
                        </Suspense>
                  </ThemeProvider>
            </>
      );
}

export default App;
