import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';

const CatalogueShow = () => {
      const { catalogueId } = useParams();
      const [catalogue, setCatalogue] = useState([]);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            ).then((response) => {
                  if (response.data.result === 'success') {
                        setCatalogue(response.data.catalogue);
                  }
            });
      }, []);

      return (
            <>
                  <div className="row">
                        <div className="col-md-12">
                              <div className="card">
                                    <div className="card-header">
                                          <div className="card-title">
                                                Catalogue Detail
                                          </div>
                                          <Link
                                                to="/dashboard/catalogues"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </div>
                                    <div className="card-body">
                                          <div className="row">
                                                <div className="col-md-4">
                                                      <div className="card">
                                                            <div className="card-body text-center">
                                                                  <img
                                                                        src={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${catalogue.image}`}
                                                                        className="w-100"
                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="col-md-8">
                                                      <ListGroup>
                                                            <ListGroup.Item>
                                                                  {' '}
                                                                  <strong>
                                                                        Name :{' '}
                                                                  </strong>{' '}
                                                                  {
                                                                        catalogue.title
                                                                  }{' '}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item className="align-items-center d-flex">
                                                                  {' '}
                                                                  <strong>
                                                                        PDF :{' '}
                                                                  </strong>{' '}
                                                                  <a
                                                                        download
                                                                        className="btn btn-warning mt-3"
                                                                        href={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${catalogue.file}`}
                                                                  >
                                                                        {' '}
                                                                        Download{' '}
                                                                  </a>{' '}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  {' '}
                                                                  <strong>
                                                                        Status :{' '}
                                                                  </strong>{' '}
                                                                  {catalogue.status ? (
                                                                        <>
                                                                              <span className="text-success">
                                                                                    Active
                                                                              </span>
                                                                        </>
                                                                  ) : (
                                                                        <>
                                                                              <span className="text-warning">
                                                                                    Disable
                                                                              </span>
                                                                        </>
                                                                  )}{' '}
                                                            </ListGroup.Item>
                                                      </ListGroup>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default CatalogueShow;
