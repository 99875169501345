import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';
import { DeleteIcon, EditIcon, ShowIcon } from '../../components/icons';

const FolderList = () => {
      const [folders, setFolders] = useState([]);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      useEffect(() => {
            const fetchData = async () => {
                  await axios
                        .get(`http://127.0.0.1:8000/api/dashboard/folders`, {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        })
                        .then((response) => {
                              setFolders(response.data.folders);
                        });
            };
            fetchData();
      }, []);

      const handleStatus = async (folder) => {
            const data = {
                  id: folder.id
            };

            await axios
                  .post(
                        `http://127.0.0.1:8000/api/dashboard/folders/status/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  });
      };

      return (
            <>
                  <div className="row">
                        <div className="col-md-12">
                              <div className="card">
                                    <div className="card-header">
                                          <div className="card-title">
                                                Folder List
                                          </div>
                                          <div className="">
                                                <Link
                                                      to="/dashboard/folders/create"
                                                      className="btn btn-link"
                                                >
                                                      Add New
                                                </Link>
                                          </div>
                                    </div>
                                    <div className="card-body">
                                          <Table className="table table-bordered table-hover">
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th width="80px">
                                                                  Status
                                                            </th>
                                                            <th width="100px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            folders.map(
                                                                  (
                                                                        folder,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                folder.title
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      folder.status ==
                                                                                                      '1'
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      handleStatus(
                                                                                                            folder
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          <Link
                                                                                                to={`/dashboard/folders/${folder.slug}/edit`}
                                                                                                className="text-secondary"
                                                                                          >
                                                                                                <EditIcon />
                                                                                          </Link>
                                                                                          <Link
                                                                                                to={`/dashboard/folders/${folder.slug}/show`}
                                                                                                className="text-success mx-2"
                                                                                          >
                                                                                                <ShowIcon />
                                                                                          </Link>
                                                                                          <a
                                                                                                role="button"
                                                                                                className="text-danger"
                                                                                          >
                                                                                                <DeleteIcon />
                                                                                          </a>
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    4
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Folder
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </Table>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default FolderList;
