import React from 'react';

const DealerIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        manage_accounts
                  </span>
            </>
      );
};

export default DealerIcon;
