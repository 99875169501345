import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteIcon } from '../../components/icons';

const CatalogueRequestList = () => {
      const [catalogues, setCatalogues] = useState([]);
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            const fetchCatalogue = async () => {
                  setLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashoard/catalogues/download/lists`
                        )
                        .then((response) => {
                              if (response.data.result == 'success') {
                                    setCatalogues(response.data.catalogues);
                                    setLoading(false);
                              }
                        });
            };

            fetchCatalogue();
      }, []);

      return (
            <>
                  <div className="row">
                        <div className="col-md-12">
                              <div className="card">
                                    <div className="card-header">
                                          <div className="card-title">
                                                Downloaded List
                                          </div>
                                          <div className="">
                                                <Link
                                                      to="/dashboard/catalogues"
                                                      className="btn btn-link"
                                                >
                                                      Back
                                                </Link>
                                          </div>
                                    </div>
                                    <div className="card-body">
                                          <table className="table table-bordered table-hover">
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>
                                                                  Catalogue Name
                                                            </th>
                                                            <th>User Name</th>
                                                            <th>Email</th>
                                                            <th>Phone</th>
                                                            <th width="240px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            catalogues.map(
                                                                  (
                                                                        catalogue,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                catalogue.catalogue_name
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                catalogue.name
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                catalogue.email
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                catalogue.phone
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <button className="btn btn-link btn-danger">
                                                                                                <DeleteIcon />
                                                                                          </button>
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              person
                                                                              found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default CatalogueRequestList;
